export default {
  methods: {
    isNumeric(n) {
      const numbers = /^[0-9]+$/
      return n.match(numbers)
    },
    formatNumber(value, decimalPlaces = 2, preSymbol = '', postSymbol = '') {
      try {
        let val = value
        if (value === null) {
          return 'N/A'
        }
        if (!value) {
          // eslint-disable-next-line
          value = 0
        }
        val = (value / 1).toFixed(decimalPlaces).replace('.', ',')
        let formattedVal = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
        // eslint-disable-next-line
        formattedVal = preSymbol + formattedVal + postSymbol
        return formattedVal
      } catch (e) {
        console.log(e)
      }
      return value
    },
    formatMoney(value, includeMoneySymbol = true) {
      if (value) {
        let formattedVal = this.formatNumber(value, 2)
        if (includeMoneySymbol) {
          // eslint-disable-next-line
          formattedVal = 'R$  ' + formattedVal
        }
        return formattedVal
      }
      return value
    },
    formatDocument(value) {
      if (value) {
        const exp = /-|\.|\/|\(|\)| /g
        value = value.toString().replace(exp, '') // eslint-disable-line
        if (value.length === 14) {
          value = value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5') // eslint-disable-line
        } else {
          value = value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4') // eslint-disable-line
        }
      }
      return value
    },
    formatZipCode(value) {
      if (value) {
        const exp = /-|\.|\/|\(|\)| /g
        value = value.toString().replace(exp, '') // eslint-disable-line
        if (value.length === 8) {
          value = value.replace(/^(\d{5})(\d{3})/, '$1-$2') // eslint-disable-line
        }
      }
      return value
    },
    formatPeriod(value) {
      value = value.replace('days', 'dias').replace('months', 'meses') // eslint-disable-line
      return value
    },
    escapeJSON(json) {
      if (json) {
        json = JSON.stringify(json) // eslint-disable-line
      }
      return json.replace(/\\b/g, ' ')
        .replace(/'\t'/g, ' ')
        .replace(/\\n/g, ' ')
        .replace(/'\f'/g, ' ')
        .replace(/\\r/g, ' ')
        .replace(/'/g, '')
        .replace(/"/g, '')
        .replace(/\\/g, '\\\\')
    },
    decodeData(data) {
      if (data) {
        const textArea = document.createElement('textarea')
        textArea.innerHTML = JSON.parse('"' + this.escapeJSON(data) + '"') // eslint-disable-line
        return textArea.value
      }
      return ''
    },
    getPhone(countryCode, areaCode, phoneNumber, formatted = false) {
      const exp = /-|\.|\/|\(|\)| /g
      areaCode = areaCode ? areaCode.toString().replace(exp, '') : '' // eslint-disable-line
      areaCode = this.isNumeric(areaCode) ? parseInt(areaCode).toString() : '' // eslint-disable-line

      phoneNumber = phoneNumber ? phoneNumber.toString().replace(exp, '') : '' // eslint-disable-line
      phoneNumber = this.isNumeric(phoneNumber) ? parseInt(phoneNumber).toString() : '' // eslint-disable-line

      let completePhone = countryCode + areaCode + phoneNumber

      if (completePhone.length > 12 && completePhone.indexOf('5555') === 0) {
        completePhone = completePhone.substring(2)
      }

      if (formatted) {
        let format = /^(\d{2})(\d{4})(\d)/
        if (completePhone[2] === '9') {
          format = /^(\d{2})(\d{5})(\d)/
        }
        completePhone = completePhone.replace(format, '($1) $2-$3') // eslint-disable-line
      }
      return completePhone
    },
    clipboardSuccessHandler(e) {
      if (e.trigger.dataset.copyField) {
        this.$root.showMessageSuccess(this.$i18n.t('common.messages.success_copy', { field: e.trigger.dataset.copyField }), this.$i18n.t('common.messages.success'), 'CopyIcon')
      }
    },
    getGreetingByHour() {
      const dt = new Date()
      const time = dt.getHours()
      let greeting = 'Bom dia, '
      if (time < 6) {
        greeting = 'Boa noite, '
      } else if (time >= 12) {
        if (time < 18) {
          greeting = 'Boa tarde, '
        } else {
          greeting = 'Boa noite, '
        }
      }
      return greeting
    },
  },
}
