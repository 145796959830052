import { parse, format } from 'date-fns'

export default {
  methods: {
    getDatesFromRange(range) {
      if (range) {
        let dates = []
        if (Array.isArray(range)) {
          dates.push(range[0])
          dates.push(' ')
          dates.push(range[1])
        } else {
          dates = range.split(' ')
        }

        if (dates.length > 0) {
          const startDate = format(parse(dates[0], 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd')
          let endDate = startDate
          if (dates.length === 3) {
            endDate = format(parse(dates[2], 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd')
          }
          const rangeDate = {
            startDate,
            endDate,
          }
          return rangeDate
        }
      }
      return {
        startDate: format(new Date(), 'yyyy-MM-dd'),
        endDate: format(new Date(), 'yyyy-MM-dd'),
      }
    },
  },
}
