import ExcelJS from 'exceljs'
import FileSaver from 'file-saver'

export default {

  downloadFile(data) {
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet(data.worksheetName)
    worksheet.columns = data.columns
    worksheet.getRow(1).font = { name: 'Calibri', size: 11, bold: true }
    worksheet.addRows(data.rows)
    if (data.summary) {
      worksheet.addRows(data.summary).forEach(row => {
        row.font = { name: 'Calibri', size: 11, bold: true } // eslint-disable-line no-param-reassign
      })
    }
    if (data.format === 'xlsx') {
      workbook.xlsx.writeBuffer().then(buffer => {
        FileSaver.saveAs(
          new Blob(
            [this.s2ab(buffer)],
            { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' },
          ),
          data.fileName,
        )
      })
    }
    if (data.format === 'csv') {
      workbook.csv.writeBuffer({ encoding: 'utf-8' }).then(buffer => {
        FileSaver.saveAs(
          new Blob(
            [buffer],
            { type: 'text/csv;charset=utf-8;' },
          ),
          data.fileName,
        )
      })
    }
  },
  s2ab(s) {
    for (let i = 0; i !== s.length; ++i) { // eslint-disable-line
      s[i] = s[i] & 0xFF // eslint-disable-line
    }
    return s
  },
}
