<template>
  <label class="btn btn-rad text-reader col-md-12" :class="'btn-' + variant">
    <font-awesome-icon :icon="icon" fixed-width /> <span :class="textClass">{{ text }}</span>
    <input type="file" @change="loadTextFromFile" :accept="accept">
  </label>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: 'Carregar Arquivo',
    },
    textClass: {
      type: String,
      default: '',
    },
    icon: {
      type: Array,
      default: () => ['fas', 'send'],
    },
    variant: {
      type: String,
      default: 'outline-primary',
    },
    accept: {
      type: String,
      default: '.txt',
    },
  },
  methods: {
    loadTextFromFile(ev) {
      const file = ev.target.files[0]
      const reader = new FileReader()

      reader.onload = e => this.$emit('load', e.target.result)
      reader.readAsText(file)
    },
  },
}
</script>

<style>
.text-reader input {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
}
</style>
