<template>
  <b-card no-body>
    <b-card-header class="p-0">
      <b-card-title class="text-primary pb-1">
        <font-awesome-icon :icon="['far', 'list-alt']" fixed-width /> {{ $t('components.cards.meli.settings.blocklists.bulk_title') }}
      </b-card-title>
    </b-card-header>
    <b-card-body class="p-0">
      <b-row>
        <b-col sm="10" md="10" lg="6" xl="4" class="mb-1">
          <b-form-group :description="$t('components.cards.meli.settings.blocklists.bulk_placeholder')">
            <b-input-group>
              <b-form-textarea id="textarea" v-model="buyerBlocklist" :rows="5" no-resize size="sm"
                :placeholder="$t('components.cards.meli.settings.blocklists.bulk_placeholder')">
              </b-form-textarea>
              <b-input-group-append v-if="!processing" is-text @click="buyerBlocklist = ''" v-b-tooltip.hover.v-primary :title="$t('common.reset')">
                <font-awesome-icon :icon="['fas', 'times']" class="text-danger"/>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="2" class="mb-1">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="mb-1" :disabled="processing" block variant="success" @click="massBlock">
            <font-awesome-icon v-if="!processing" :icon="['fas', 'lock']" fixed-width />
            <font-awesome-icon v-else :icon="['fas', 'spinner']" pulse fixed-width />
            {{ $t('components.cards.meli.settings.blocklists.bulk_block') }}
          </b-button>
          <file-reader v-if="!processing" variant="outline-primary" :icon="['fas', 'upload']" class="mb-1" :text="$t('common.upload_file')"
            @load="loadFile"></file-reader>
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" :disabled="processing" block variant="outline-danger"
            @click="prepareMassBlocklist">
            <font-awesome-icon :icon="['fas', 'reply']" /> {{ $t('common.back') }}
          </b-button>
          <small v-if="(usersToBlock.length + processingBlocklist.length) > 0" class="text-danger float-right" v-html="$t('components.cards.meli.settings.blocklists.bulk_users_left', { users: usersToBlock.length + processingBlocklist.length })"></small><br/>
        </b-col>
        <b-col sm="10" md="10" lg="6" xl="6" class="mb-1">
          <b-alert :show="processing || usersToBlock.length > 1000" variant="warning" >
            <div class="alert-body">
              <span>{{ $t('components.cards.meli.settings.blocklists.messages.bulk_info') }}</span>
            </div>
          </b-alert>
          <b-alert :show="processing" variant="warning">
            <div class="alert-body">
              <span>{{ $t('components.cards.meli.settings.blocklists.messages.bulk_processed_started') }} <font-awesome-icon :icon="['fas', 'spinner']" pulse fixed-width /></span>
            </div>
          </b-alert>
          <b-alert :show="finished" variant="success">
            <div class="alert-body">
              <span>{{ $t('components.cards.meli.settings.blocklists.messages.bulk_processed_finished') }}
              </span>
            </div>
          </b-alert>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="4" v-for="block in finishedBlocklist" :key="block.id">
          <b-alert v-height-fade.appear :show="block.dismissCountDown" :variant="block.status"
            @dismissed="block.dismissCountDown = 0" @dismiss-count-down="countDownChanged($event, block)">
            <div class="alert-body">
              <span>{{ block.message }}</span>
            </div>
          </b-alert>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BButton, BRow, BCol, BFormGroup, BInputGroup, BInputGroupAppend, BFormTextarea, BAlert, VBTooltip,
} from 'bootstrap-vue'
import { heightFade } from '@core/directives/animations'
import FileReader from '@core-md/components/FileReader.vue'
import Ripple from 'vue-ripple-directive'
import meliUsers from '@/services/meli/users'

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormTextarea,
    BAlert,
    FileReader,
  },
  props: {
    account: {
      type: Object,
      default: null,
    },
    inherit: {
      type: Boolean,
      default: true,
    },
  },
  directives: {
    'height-fade': heightFade,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      selectedAccount: null,
      buyerBlocklist: '',
      errorsToBlock: [],
      finishedBlocklist: [],
      processingBlocklist: [],
      processing: false,
      maxFinished: 30,
      itemsToProcess: 50,
      finished: false,
      showMass: false,
      unWatchSelectedAccount: null,
      timer: null,
    }
  },
  computed: {
    usersToBlock() {
      if (this.buyerBlocklist.trim()) {
        return this.buyerBlocklist.trim().split('\n')
      }
      return []
    },
  },
  mounted() {
    if (!this.inherit || this.account) {
      this.selectedAccount = this.account
    } else {
      this.selectedAccount = this.$store.state.authorizations.selectedAccount
      this.unWatchSelectedAccount = this.$store.watch(() => this.$store.state.authorizations.selectedAccount, account => {
        if (account) {
          this.selectedAccount = account
        }
      })
    }
    this.$store.dispatch('meli/initialize')
  },
  beforeDestroy() {
    if (this.unWatchSelectedAccount) {
      this.unWatchSelectedAccount()
      this.unWatchSelectedAccount = null
    }
    if (this.timer) {
      clearTimeout(this.timer)
      this.timer = null
    }
  },
  methods: {
    prepareMassBlocklist() {
      this.$emit('updateMass', false)
    },
    massBlock() {
      if (this.usersToBlock.length > 0) {
        if (!this.processing) {
          this.finishedBlocklist = []
          this.processing = true
          this.finished = false
          this.$root.showMessageWarning(this.$i18n.t('components.cards.meli.settings.blocklists.messages.bulk_processed_started'), this.$i18n.t('components.cards.meli.settings.blocklists.messages.bulk_title_block'), 'LockIcon')
        }

        while (this.usersToBlock.length > 0) {
          if (this.processingBlocklist.length >= this.itemsToProcess) {
            this.timer = setTimeout(() => {
              this.massBlock()
            }, 1000)

            return
          }

          const user = this.usersToBlock.shift()
          this.processingBlocklist.push(user)
          this.buyerBlocklist = this.usersToBlock.join('\n')
          this.blockUser(user)
        }

        this.showFinishedMessage()
      }
    },
    blockUser(user) {
      if (user && user.length >= 3) {
        meliUsers.searchUser(this.selectedAccount.access_token, user).then(response => {
          if (response.httpCode === 200 && response.body.id) {
            this.addOrderBlocklist(response.body)
          } else {
            this.processingBlocklist = this.processingBlocklist.filter(item => item !== user)
            this.addToFinishedList(user, 'info', `${this.$i18n.t('components.cards.meli.settings.blocklists.messages.user_not_found')} : ${user}`)
            this.errorsToBlock.push(user)
          }
        })
      } else {
        this.processingBlocklist = this.processingBlocklist.filter(item => item !== user)
        this.addToFinishedList(user, 'info', `${this.$i18n.t('components.cards.meli.settings.blocklists.messages.min_length')} : ${user}`)
        this.errorsToBlock.push(user)
      }
    },
    addOrderBlocklist(userItem) {
      meliUsers.addToOrderBlocklist(this.selectedAccount.access_token, this.selectedAccount.seller_id, userItem.id).then(response => {
        if (response.httpCode === 200 || response.httpCode === 201) {
          this.addToFinishedList(userItem.id, 'success', this.$i18n.t('components.cards.meli.settings.blocklists.messages.success_block', { user: userItem.id }))
        } else {
          this.addToFinishedList(userItem.id, 'danger', this.$i18n.t('components.cards.meli.settings.blocklists.messages.error_block', { user: userItem.id }))
          this.errorsToBlock.push(userItem.id)
        }
        this.processingBlocklist = this.processingBlocklist.filter(item => item.toString() !== userItem.id.toString() && item !== userItem.nickname)
      })
    },
    addToFinishedList(userId, status, message) {
      const userToBlock = {
        id: userId,
        status,
        message,
        dismissCountDown: 10,
      }
      if (this.finishedBlocklist.length >= this.maxFinished) {
        this.finishedBlocklist.shift()
      }
      this.finishedBlocklist.push(userToBlock)
    },
    showFinishedMessage() {
      const leftToProcess = this.finishedBlocklist.filter(item => item.dismissCountDown > 7)
      if (leftToProcess.length > 0) {
        this.timer = setTimeout(() => {
          this.showFinishedMessage()
        }, 2000)
      } else {
        this.buyerBlocklist = this.errorsToBlock.join('\n')
        this.errorsToBlock = []
        this.$root.showMessageSuccess(this.$i18n.t('components.cards.meli.settings.blocklists.messages.bulk_processed_finished'), this.$i18n.t('components.cards.meli.settings.blocklists.messages.bulk_title_block'), 'LockIcon')
        this.processing = false
        this.finished = true
      }
    },
    countDownChanged(dismissCountDown, block) {
      block.dismissCountDown = dismissCountDown // eslint-disable-line
    },
    loadFile(content) {
      this.buyerBlocklist = content.replaceAll('\r', '')
    },
  },
}
</script>
