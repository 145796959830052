<template>
  <b-row v-if="showLoading || showNotFound" class="mt-3">
    <b-col sm="12" class="text-center align-middle">
      <h3>
        <span v-if="showLoading" :class="colorVariant('text-', 'primary')">
          <b-spinner class="mr-50" :type="spinnerType" />
          {{ $t('components.table_info.loading') }}
        </span>
        <span v-if="!showLoading && showNotFound" :class="colorVariant('text-', 'danger')">
          <font-awesome-icon :icon="['fas', 'search']" class="mr-50" fixed-width />
          {{ $t('components.table_info.not_found') }}
        </span>
      </h3>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BSpinner } from 'bootstrap-vue'
import variants from '@core-md/mixins/variants'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BRow,
    BCol,
    BSpinner,
  },
  props: {
    showLoading: {
      type: Boolean,
      default: false,
    },
    showNotFound: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [
    variants,
  ],
  setup() {
    const { spinnerType } = $themeConfig.layout

    return {
      spinnerType,
    }
  },
}
</script>
