<template>
  <b-card no-body>
    <HTMLTitle :title="$t('components.cards.meli.settings.blocklists.page_title')"></HTMLTitle>

    <card-header :title="$t('components.cards.meli.settings.blocklists.page_title')"
      :id="'meliBlocklist'"
      :icon="['fas', 'user-slash']"
      :subTitle="(selectedAccount && selectedAccount.nickname) || $t('components.cards.common.select_account')"
      :description="$t('components.cards.meli.settings.blocklists.page_info')"></card-header>

    <b-card-body class="p-0">
      <b-tabs content-class="pl-1 pr-1 pl-sm-2 pr-sm-2" nav-class="pl-1 pr-1 pl-sm-2 pr-sm-2" justified>

        <b-tab v-for="tab in tabs" :key="tab.index">
          <template #title>
            <font-awesome-icon :icon="tab.icon" class="m-0" fixed-width />
            <span class="font-weight-bold ml-50">{{
              $t(tab.title)}}</span>
          </template>
          <component :is="tab.component" :options="options" />
        </b-tab>

      </b-tabs>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BTabs, BTab,
} from 'bootstrap-vue'
import HTMLTitle from '@core-md/layouts/components/HTMLTitle.vue'
import CardHeader from '@core-md/components/cards/CardHeader.vue'
import CardOrdersBlocklist from '@/layouts/components/cards/meli/settings/blocklists/CardOrdersBlocklist.vue'
import CardQuestionsBlocklist from '@/layouts/components/cards/meli/settings/blocklists/CardQuestionsBlocklist.vue'

export default {
  components: {
    HTMLTitle,
    CardHeader,
    BCard,
    BCardBody,
    BTabs,
    BTab,
    CardOrdersBlocklist,
    CardQuestionsBlocklist,
  },
  props: {
    account: {
      type: Object,
      default: null,
    },
    inherit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      options: {
        showTitle: false,
        showAccount: false,
        showInfo: false,
      },
      tabs: [
        {
          title: 'components.cards.meli.settings.blocklists.orders',
          icon: ['fas', 'shopping-cart'],
          component: 'CardOrdersBlocklist',
        },
        {
          title: 'components.cards.meli.settings.blocklists.questions',
          icon: ['far', 'comment'],
          component: 'CardQuestionsBlocklist',
        },
      ],
      selectedAccount: null,
      unWatchSelectedAccount: null,
    }
  },
  mounted() {
    if (!this.inherit || this.account) {
      this.selectedAccount = this.account
    } else {
      this.selectedAccount = this.$store.state.authorizations.selectedAccount
      this.unWatchSelectedAccount = this.$store.watch(() => this.$store.state.authorizations.selectedAccount, account => {
        if (account) {
          this.selectedAccount = account
        }
      })
    }
  },
  beforeDestroy() {
    if (this.unWatchSelectedAccount) {
      this.unWatchSelectedAccount()
      this.unWatchSelectedAccount = null
    }
  },
}
</script>
