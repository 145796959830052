<template>
  <span class="small"><strong><span class="d-none d-sm-inline">{{ $t('common.paginator.showing') }} </span>{{ $t('common.paginator.summary', {'start': formatNumber(start, 0), 'end': formatNumber(end, 0), 'total': formatNumber(total, 0)}) }}<span class="d-none d-sm-inline"> {{ $t('common.paginator.records') }}</span></strong></span>
</template>

<script>
import stringHelper from '@core-md/mixins/stringHelper'

export default {
  props: {
    pagination: {
      type: Object,
      default: null,
    },
  },
  mixins: [
    stringHelper,
  ],
  computed: {
    start() {
      if (this.pagination) {
        return (1 + (this.pagination.perPage * (this.pagination.currentPage - 1)))
      }
      return 0
    },
    end() {
      let aux = 0
      if (this.pagination) {
        aux = this.pagination.perPage * this.pagination.currentPage
        if (aux <= this.total) {
          return aux
        }
        return this.total
      }
      return 0
    },
    total() {
      if (this.pagination) {
        return this.pagination.totalRows || 0
      }
      return 0
    },
  },
}
</script>
